.block-main-container {
    padding: 0 80px;
    margin-top: 50px;
}
.block-desc-wrap {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    margin-bottom:50px;
}
.block-desc-wrap h2 {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    font-size: 30px;

}
.block-desc-wrap h2::after {
    content: '';
    display: inline-block;
    flex: 1;
    border-bottom: 3px solid #cfe2ee;
    margin: 0 0 15px 50px;
    width: 100%;
    align-self: flex-end;
}
.about-callout-wrap {
    display: flex;
    flex-direction: row;    
    gap: 40px;
    justify-content: space-around;
}


.about-callout {
     /* background: #cfe2ee; */
     border: 1px solid #e5e7eb;
     border-radius: 10px;
     display: flex;
     flex-direction: column;
     padding: 50px;
     text-align: center;
     flex-wrap: wrap;
     width: 32.666%;
     box-sizing: border-box;
     margin-top: 40px;
}
.icon-wrap {
    padding: 0;
    border: 1px solid #e5e7eb;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
}
@media screen and (max-width: 768px) {
    .about-callout-wrap {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .about-callout {
        width: 100%;
    }
}
.icon-wrap .icon {
    font-size: 30px;;
}
.content-wrap {
    flex-grow: 1;
}
.content-wrap h3 {
    font-size: 25px;
}