.posts-container-wrap {
    display: flex;
    margin-bottom: 50px;
}

.posts-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 60px;
}

.home_posts {
    box-sizing: border-box;
    text-decoration: none;
}

