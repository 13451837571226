
.sub-container {
    height: 100%;
}
.main-block {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation: changeBackground 10s infinite; /* seconds total, for 3 images */
}

/* Define keyframes to change background image */
@keyframes changeBackground {
    0% {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../assets/penn-hall.jpg');
        background-size: cover; /* Ensure the background covers the entire block */
        background-position: center;
        background-repeat: no-repeat;
    }
    33% {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../assets/harvard.jpg');
        background-size: cover; /* Ensure the background covers the entire block */
        background-position: center;
        background-repeat: no-repeat;
    }
    66% {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../assets/mit.jpg');
        background-size: cover; /* Ensure the background covers the entire block */
        background-position: center;
        background-repeat: no-repeat;
    }
    100% {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../assets/harvard2.jpg'); 
        background-size: cover; 
        background-position: center;
        background-repeat: no-repeat;
    }
}
.block-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}
.block-text-wrapper h5 {
    font-size: 20px;
    margin: 0;
}
.main-title {
    margin: 0;
    font-size: 60px;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
}
.slogan {
    font-style: italic;
    font-size: 14px;;
}
